import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { computed, useAttrs } from "vue";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLink',
  props: {
    linkComponent: { default: "a" },
    strictTarget: {}
  },
  setup(__props: any) {

const props = __props;

const attrs = useAttrs();

const { getNewTabEnabled } = storeToRefs(useWebCustomerStore());

const targetValue = computed(() => {
  if (props.strictTarget) return props.strictTarget;

  if (isExternalLink(String(attrs.href || attrs.to || ""))) {
    return "_blank";
  }

  return getNewTabEnabled.value && (attrs.to as string)?.slice(0, 1) !== "#"
    ? "_blank"
    : "_self";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.linkComponent), _mergeProps(_ctx.$attrs, { target: targetValue.value }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["target"]))
}
}

})