
import { defineComponent } from "vue";
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

const __default__ = defineComponent({
  name: "AppInformationGroup",

  // eslint-disable-next-line
  components: { AppInformation, AppLink },

  props: {
    label: {
      type: String,
      required: true,
    },
    titleFontWeight: {
      type: Number,
      default: 300,
    },
    titleFontSize: {
      type: String,
      default: "14px",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    explanation: String,
    information: String,
    allowHtml: Boolean,
    isRequired: Boolean,
    gap: {
      type: String,
      default: "8px",
    },
  },

  computed: {
    hasTooltipContent() {
      return this.information && this.information.length > 0;
    },

    titleStyle() {
      return {
        fontWeight: this.titleFontWeight,
        fontSize: this.titleFontSize,
      };
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "371ee471": (_ctx.gap)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__