// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  capitalize(): string;
  toEuro(): string;
  toDollar(): string;
  toCamelCase(): string;
}

String.prototype.capitalize = function (): string {
  if (this) {
    return this[0].toUpperCase() + this.slice(1);
  }

  return this;
};

String.prototype.toEuro = function (): string {
  if (this) {
    return `€ ${this}`;
  }

  return this;
};

String.prototype.toDollar = function (): string {
  if (this) {
    return `$ ${this}`;
  }

  return this;
};

String.prototype.toCamelCase = function (): string {
  return this.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, "");
};
