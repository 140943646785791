import { Sort, AppSearchbarOption } from "@/shared/types/components";

export interface StartupClaimProfile {
  logoURL: string;
  tradingName: string;
  website: string;
  noWebsite: boolean;
  searchableLocationID: number;
  linkedinURL?: string;
  noLinkedinURL: boolean;
  foundedYear: string;
  noFoundedYear: boolean;
  intellectualProperty: boolean;
  uniqueSellingProposition: string;
  impact: string;
  pitchdeckURL: string;
  isFundRaising?: boolean;
  employeeGrowth: EmployeeGrowth[];
  managementTeamMembers: ManagementTeamMember[];
  dealClaims: DealClaim[];
  revenueClaims: RevenueClaim[];
  noDealClaims: boolean;
  noRevenueClaims: boolean;
}

export interface EmployeeGrowth {
  quarter?: number;
  year?: number;
  count?: number;
}

export interface ManagementTeamMember {
  name: string;
  email: string;
  phone?: string;
  role: string;
  linkedinURL?: string;
  claimer?: boolean;
  founder?: boolean;
  id?: number;
}

export interface TeamInfo {
  name: string;
  exportCredits: number;
  teamMembers: TeamMember[];
}

export interface RevenueClaim {
  year: number;
  revenue?: number;
  EBITDA?: number;
  currency: string;
  hide: boolean;
  id?: string;
}
export interface AddRevenueClaim {
  revenue?: number;
  EBITDA?: number;
  year: AppSearchbarOption;
  currency: AppSearchbarOption;
  hide: boolean;
}

export interface DealClaim {
  id?: number;
  roundDate?: string;
  roundType?: string;
  roundTypeID?: number;
  roundAmount?: number;
  roundAmountUSD?: number;
  originalRoundAmount?: number;
  roundCurrency?: string;
  originalValuation?: number;
  valuation?: number;
  valuationUSD?: number;
  valuationCurrency?: string;
  hideValuation: boolean;
  investorClaims: InvestorClaim[];
  noValuation?: boolean;
}

export interface InvestorClaim {
  id: number;
  investorID?: number;
  name: string;
  website?: string;
  domain?: number;
  linkedinURL?: string;
}

export enum ClaimProfileSteps {
  BASIC_INFORMATION = "basic-information",
  FUNDING_ROUNDS = "funding-rounds",
  REVENUE_FINANCIALS = "revenues-financials",
  EMPLOYEES_COUNT = "employees-count",
  MANAGEMENT_TEAM = "management-team",
  FINAL = "final",
}

export enum ClaimProfileStepsComponents {
  "basic-information" = "BasicInformationForm",
  "funding-rounds" = "FundingRoundsAndValuationForm",
  "revenues-financials" = "RevenuesAndFinancialsForm",
  "employees-count" = "EmployeeCountForm",
  "management-team" = "ManagementTeamForm",
  "final" = "FinalForm",
}

export interface ProfileClaimErrorModel {
  value: boolean;
  checkIsValid: (value: StartupClaimProfile) => boolean;
}

export interface InboundModel {
  clientID: null | number;
  claimerEmail: null | string;
  startupProfile: StartupClaimProfile;
}

export interface StartupProfileClaimBody {
  startupProfile: StartupClaimProfile;
  clientID?: number;
  claimerEmail?: string;
  token?: string;
  url?: string;
}

export enum InboundSteps {
  AddYourOrganizationStep = "AddYourOrganizationStep",
  CreateYourOrganizationStep = "CreateYourOrganizationStep",
  EmailVerificationStep = "EmailVerificationStep",
}

export enum SubmissionStatus {
  ALL = "ALL",
  SUBMITTED = "SUBMITTED",
  VALIDATED = "VALIDATED",
}

export enum SubmissionNumber {
  ALL = "ALL",
  LAST = "LAST",
}

export enum RevenueOrEBITDA {
  REVENUE = "revenue",
  EBITDA = "EBITDA",
}

export enum TeamMemberAccessOptionsValue {
  EDIT = "EDIT",
  READ_ONLY = "READ_ONLY",
}

export interface ProfileClaimSubmissionFilter {
  sorting?: Sort;
  submissionStatus: SubmissionStatus;
  submissionNumber: SubmissionNumber;
  clientID?: number;
  submittedFrom?: Date;
  submittedTo?: Date;
  validatedFrom?: Date;
  validatedTo?: Date;
  limit: number;
  offset: number;
}

export interface ProfileClaimSubmission {
  logoURL: string;
  name: string;
  website: string;
  linkedin: string;
  submitted: Date;
  validated: Date;
  directLink: string;
  status: string;
  token: string;
}

export interface TagPOJO {
  id?: number;
  label: string;
  description?: string;
  tagTypeLabel?: string;
  tagFamilyLabel?: string;
  tagTypeID?: number;
  customer: boolean;
  customerIDs?: number[];
  platform: boolean;
  advancedFilters: boolean;
  filterable: boolean;
  visible: boolean;
  umbrella: boolean;
  rawSuggestedSearches?: string[];
}

export interface TagFilter {
  name?: string;
  tagTypeID?: number;
  tagID?: number;
  tagFamilyID?: number;
  customer?: boolean;
  platform?: boolean;
  advancedFilters?: boolean;
  filterable?: boolean;
  visible?: boolean;
  customerID?: number;
  offset?: number;
  umbrella?: boolean;
  limit?: number;
}

export type TagFilterValues = string | boolean | number | undefined;

export interface TagTypePOJO {
  id: number;
  label: string;
  tagFamily: TagFamilyPOJO;
  platformOrder?: number;
}

export interface TagFamilyPOJO {
  id: number;
  label: string;
  platformOrder?: number;
}

export interface TeamMemberAccessOption {
  label: string;
  value: TeamMemberAccessOptionsValue;
}

export interface TeamMember {
  id: number;
  name: string;
  email: string;
  surname: string;
  shareMode?: string;
  value?: number;
  label?: string;
  placeholder?: string;
  access?: TeamMemberAccessOption;
}
