<script setup lang="ts">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";
import { computed } from "vue";

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(val: boolean) {
    emit("update:modelValue", val);
  },
});
</script>

<template>
  <AppModal v-model="model" show-header-border>
    <template #header>
      <h3 class="modal-title">Why should I submit my info?</h3>
    </template>
    <template #body>
      Being a part of the Net0 Platform is a critical stride towards
      accelerating your startup's success in the climate tech landscape.
      <br />
      <br />

      <ul>
        <li>
          Gain unmatched visibility among top-tier investors & unlock exclusive
          advantages for fundraising and growth.
        </li>
        <li>
          Get full access to a vast climate tech investor database to propel
          your fundraising efforts.
        </li>
        <li>
          Become eligible for exclusive coupons & rewards designed to upscale
          your business.
        </li>
      </ul>
    </template>
    <template #footer="{ hideModal }">
      <AppButton label="GOT IT" type="empty" @click="hideModal" />
      <AppButton
        label="Learn more"
        href="https://netzeroinsights.com/register-on-the-net0-platform/"
      />
    </template>
  </AppModal>
</template>
