<template>
  <AppModal class="book-call-modal" v-model="model" size="xsmall">
    <template #header>
      <h3 class="modal-title">Book a call with your account manager</h3>
    </template>
    <template #body>
      <div
        v-for="manager in managers"
        :key="manager.name"
        class="book-call-modal__manager"
      >
        <AppIcon
          class="book-call-modal__manager__avatar"
          :alt="manager.name"
          :src="manager.avatar"
          size="100px"
        />
        <p class="book-call-modal__message">{{ manager.name }}</p>
        <AppButton
          class="book-call-modal__manager__book-now"
          :href="manager.link"
          label="Book now"
        />
      </div>
    </template>
    <template #footer="{ hideModal }">
      <AppButton label="Cancel" type="empty" @click="hideModal" />
    </template>
  </AppModal>
</template>

<script setup lang="ts">
import AppModal from "@/components/app/AppModal/AppModal.vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";

const model = defineModel({ required: true, type: Boolean });

const managers = [
  {
    name: "Andrea Canepa",
    avatar:
      "https://res.cloudinary.com/eutopia-3/image/upload/v1722340655/andrea_cpagcu.jpg",
    link: "https://meetings-eu1.hubspot.com/andrea-canepa/andrea-renewals",
  },
  {
    name: "Federico Cristoforoni",
    avatar:
      "https://res.cloudinary.com/eutopia-3/image/upload/v1722340655/federico_ij8ier.jpg",
    link: "https://meetings-eu1.hubspot.com/federico-cristoforoni/clone",
  },
];
</script>

<style lang="scss" scoped>
@import "@/styles/functions.scss";

.book-call-modal {
  &:deep(.app-modal__content) {
    .app-modal__content {
      &__header {
        text-align: center;
        font-size: rem(18px);
      }

      &__body {
        display: flex;
        justify-content: center;
        gap: rem(64px);
      }
    }
  }

  &__manager {
    text-align: center;

    &__book-now {
      margin: 0 auto;
    }

    &__avatar {
      border-radius: 50%;
    }
  }
}
</style>
