export enum AppModalSizeEnum {
  xsmall = "25vw",
  small = "37.5vw",
  medium = "50vw",
  large = "60vw",
  xlarge = "75vw",
  fullscreen = "100vw",
}

export enum AppInputType {
  PASSWORD = "password",
  TEXT = "text",
  EMAIL = "email",
  NUMBER = "number",
}
