<template>
  <div class="app-information-group">
    <div>
      <div
        :class="[
          'app-information-group__label',
          { 'app-information-group__label--line': showLine },
        ]"
        data-testid="appInformationGroupLabel"
      >
        <span
          :class="[
            'app-information-group__label__title',
            { 'app-information-group__label__title--required': isRequired },
          ]"
          :style="titleStyle"
        >
          {{ label }}
        </span>
        <slot name="trailing" />
        <AppInformation
          v-if="hasTooltipContent"
          :content="information"
          data-testid="appInformationGroupLabelInfo"
          :allow-html="allowHtml"
        />
      </div>
      <template v-if="explanation">
        <small
          v-if="allowHtml"
          data-testid="appInformationGroupExplanation"
          v-html="explanation"
        />
        <small v-else data-testid="appInformationGroupExplanation">
          {{ explanation }}
        </small>
      </template>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

export default defineComponent({
  name: "AppInformationGroup",

  // eslint-disable-next-line
  components: { AppInformation, AppLink },

  props: {
    label: {
      type: String,
      required: true,
    },
    titleFontWeight: {
      type: Number,
      default: 300,
    },
    titleFontSize: {
      type: String,
      default: "14px",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    explanation: String,
    information: String,
    allowHtml: Boolean,
    isRequired: Boolean,
    gap: {
      type: String,
      default: "8px",
    },
  },

  computed: {
    hasTooltipContent() {
      return this.information && this.information.length > 0;
    },

    titleStyle() {
      return {
        fontWeight: this.titleFontWeight,
        fontSize: this.titleFontSize,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-information-group,
.app-information-group__label,
.app-information-group__top {
  display: flex;
}

.app-information-group {
  flex-direction: column;
  gap: v-bind(gap);
}

.app-information-group__label {
  align-items: center;
  gap: rem(8px);
}

.app-information-group__label__title {
  text-transform: uppercase;

  &--required::after {
    color: $danger;
    content: " *";
  }
}

.app-information-group__label--line::after {
  border-bottom: 1px solid $border;
  content: "";
  flex: 1 1;
}
</style>
