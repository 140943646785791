import { FreeTrialMessage } from "@/shared/types/common";
import { defineStore } from "pinia";

export default defineStore("freeTrial", {
  state: () => {
    return {
      title: "Upgrade to enjoy without restrictions the Net0 Platform",
      message:
        "You can't use this feature with your free trial subscription. Please upgrade to unlock all the features.",
      showModal: false,
    };
  },

  actions: {
    showFreeTrialModal(messageType: FreeTrialMessage) {
      this.setFreeTrialContent(messageType);

      this.showModal = true;
    },

    setFreeTrialContent(messageType: FreeTrialMessage) {
      switch (messageType) {
        case "PAGE_CHANGE":
          this.message =
            "Free trial users can display only the first 15 results per search. To see the full list of organisations matching your search criteria, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "CONTACT":
          this.message =
            "To view the full list of contacts of this organisation, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "SEARCH_LIMIT_EXCEEDED":
          this.message =
            "You have reached the limit of 100 free searches. To continue searching the Net Zero Insights Platform, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "DETAILS_LIMIT_EXCEEDED":
          this.message =
            "You have reached the limit of 50 organisation profiles. To continue accessing the profile page of the organisations featured on the Net Zero Insights Platform, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "DOWNLOAD":
          this.message =
            "You can't use this feature with your free trial subscription. Please upgrade to unlock downloading your search results.";
          break;
        case "MANUAL_CLICK":
          this.message =
            "You are using a free trial subscription. Please upgrade to unlock all the features.";
          break;
        case "SEE_MORE_RESULTS":
          this.message =
            "To view the full list of organisations similar to this one, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "PATENT_OFFICE_CHANGE":
          this.message =
            "To view the full list of the patent applications submitted by this organisation, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "FUNDING_ROUNDS_CHANGE":
          this.message =
            "To view the full list of the funding rounds of this organisation, please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "DEFAULT":
          this.message =
            "You can't use this feature with your free trial subscription. Please upgrade to unlock all the features.";
          break;
        case "EXPORT_AFFINITY":
          this.title = "Upgrade to use the Affinity integration feature";
          this.message =
            "Only premium users can use the Affinity integration feature. Please upgrade to one of our premium plans by contacting the sales team.";
          break;
        case "EXPORT_SALESFORCE":
          this.title = "Upgrade to use the Salesforce integration feature";
          this.message =
            "Only premium users can use the Salesforce integration feature. Please upgrade to one of our premium plans by contacting the sales team.";
          break;
        default:
          this.message =
            "You can't use this feature with your free trial subscription. Please upgrade to unlock all the features.";
          break;
      }
    },

    hideFreeTrialModal() {
      this.showModal = false;
    },
  },
});
