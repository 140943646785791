import type { DirectiveBinding } from "vue";

interface ClickOutsideElement extends HTMLElement {
  _clickOutside?: (event: MouseEvent) => void;
}

export const clickOutside = {
  mounted(el: ClickOutsideElement, binding: DirectiveBinding) {
    el._clickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!el.contains(target)) {
        binding.value(event);
      }
    };

    window.addEventListener("click", el._clickOutside);
  },

  unmounted(el: ClickOutsideElement) {
    if (el._clickOutside) {
      window.removeEventListener("click", el._clickOutside);
      delete el._clickOutside;
    }
  },
};
