import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unauthorized-view" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "unauthorized-view__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppLink, {
      href: "https://netzeroinsights.com",
      class: "unauthorized-view__logo"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.netZeroInsightsLogoHighRes,
          alt: "Net Zero Insights logo",
          class: "unauthorized-view__logo"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("span", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" Need help? Contact us at ")),
      _createVNode(_component_AppLink, {
        class: "unauthorized-view__footer__contact",
        href: `mailto:${_ctx.shownEmailAddress}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.shownEmailAddress), 1)
        ]),
        _: 1
      }, 8, ["href"])
    ])
  ]))
}