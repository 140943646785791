import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "claim-profile__header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "claim-profile__body" }

import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { netZeroInsightsLogoHighRes } from "@/shared/constants/icons";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import SubmitInfoModal from "@/components/profileClaim/SubmitInfoModal/SubmitInfoModal.vue";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClaimProfileLayout',
  setup(__props) {

_useCssVars(_ctx => ({
  "72cd9f83": (getBodyPadding.value)
}))

const { showSubscriptionBanner } = useWebCustomerStore();
const route = useRoute();

const showModal = ref(false);
const bookCallModal = ref(false);

const getBodyPadding = computed(() => {
  if (route.name === "ClaimProfileForm") {
    return "48px 48px 0";
  }
  return "48px";
});

const handleOpenBookCallModal = () => {
  bookCallModal.value = true;
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showSubscriptionBanner))
      ? (_openBlock(), _createBlock(AppExpiryBanner, {
          key: 0,
          onOpenBookCallModal: handleOpenBookCallModal
        }))
      : _createCommentVNode("", true),
    _createVNode(BookCallModal, {
      modelValue: bookCallModal.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((bookCallModal).value = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("header", _hoisted_1, [
      _createVNode(AppLink, {
        "link-component": "router-link",
        to: "/dashboard",
        class: "claim-profile__header__brand"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _unref(netZeroInsightsLogoHighRes),
            alt: "Net Zero Insights",
            class: "claim-profile__header__brand__logo"
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }),
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = !showModal.value)),
        class: "claim-profile__header__link"
      }, " Why should I submit my info? ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(SubmitInfoModal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showModal).value = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}
}

})