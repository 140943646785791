<template>
  <div :class="`app-toast app-toast--${type}`" v-once>
    <span class="app-toast__label">{{ label }}</span>
    <span class="app-toast__button" @click.stop="removeClick">×</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AppToastType } from "@/shared/types/components";

export default defineComponent({
  name: "AppToast",

  emits: ["remove"],
  props: {
    type: {
      type: String as PropType<AppToastType>,
      default: "information",
    },
    label: String,
  },

  methods: {
    removeClick() {
      this.$emit("remove");
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/styles/colors.scss";
@import "src/styles/functions.scss";

.app-toast {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: rem(8px);
  gap: rem(8px);
  min-height: rem(32px);
  padding: rem(24px);
  max-width: rem(700px);
}

.app-toast__label {
  color: $white;
  user-select: none;
  line-height: rem(24px);
  white-space: pre-wrap;
  word-break: break-word;
  font-size: rem(16px);
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.app-toast__button {
  cursor: pointer;
  color: $white;
  opacity: 0;
  transition: opacity 0.25s;
  font-size: rem(24px);
  font-weight: 700;
  line-height: rem(24px);
}

.app-toast:hover .app-toast__button:not(:hover) {
  opacity: 0.3;
}

.app-toast__button:hover {
  opacity: 1;
}

.app-toast--information {
  background-color: $information;
}

.app-toast--warning {
  background-color: $warning;
}

.app-toast--danger {
  background-color: $danger;
}

.app-toast--success {
  background-color: $primary;
}
</style>
