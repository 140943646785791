<template>
  <AppModal v-model="model" size="medium">
    <template #header>
      <h3 class="modal-title">Add organisation to the Net0 Platform</h3>
    </template>
    <template #body>
      <div class="add-company-modal__body">
        <AppInformationGroup
          label="COMPANY NAME"
          explanation="Please specify the name of the organisations to be added"
          :title-font-weight="400"
          isRequired
        >
          <AppInput
            v-model="company.name"
            placeholder="Type organisation name"
          />
        </AppInformationGroup>
        <AppInformationGroup
          label="COMPANY WEB PRESENCE"
          explanation="Please add <b>at least one</b> of the following: website, LinkedIn page, or any relevant link that provides more information about the organisation."
          :title-font-weight="400"
          allow-html
          isRequired
        >
          <div class="add-company-modal__body__web-presence">
            <CompanyInfoSectionItem gap="25px" is-align-center width="188px">
              <template #title> Organisation website </template>
              <template #content>
                <AppInput
                  v-model.trim="company.website"
                  placeholder="Type organisation website"
                />
              </template>
            </CompanyInfoSectionItem>
            <CompanyInfoSectionItem gap="25px" is-align-center width="188px">
              <template #title> Organisation LinkedIn URL </template>
              <template #content>
                <AppInput
                  v-model.trim="company.linkedInURL"
                  placeholder="Type organisation LinkedIn URL"
                />
              </template>
            </CompanyInfoSectionItem>
            <CompanyInfoSectionItem
              gap="25px"
              information="If the organistion lacks a website or LinkedIn page, please provide any link that offers additional information like Facebook, Twitter page, a news article, or a pitch deck."
              is-align-center
              width="188px"
            >
              <template #title> Organisation web presence </template>
              <template #content>
                <AppInput
                  v-model.trim="company.webPresence"
                  placeholder="Type organisation web presence"
                />
              </template>
            </CompanyInfoSectionItem>
          </div>
        </AppInformationGroup>
      </div>
    </template>
    <template #footer="{ hideModal }">
      <AppButton
        label="Cancel"
        type="empty"
        data-testid="cancel-button"
        @click="hideModal"
        :is-disabled="isLoading"
      />
      <AppButton
        label="Add organisation"
        data-testid="add-button"
        :is-loading="isLoading"
        :is-disabled="isAddButtonDisabled"
        @click="handleAddClick"
      />
    </template>
  </AppModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppInformationGroup from "@/components/app/AppInformationGroup/AppInformationGroup.vue";
import {
  isEmpty,
  isValidLinkedinUrl,
  isValidUrl,
} from "@/shared/helpers/validators/validators";
import CompanyInfoSectionItem from "@/components/admin/CompanyInfoSectionItem/CompanyInfoSectionItem.vue";

export default defineComponent({
  name: "AppNewCompanyModal",

  components: {
    AppButton,
    AppInput,
    AppModal,
    AppInformationGroup,
    CompanyInfoSectionItem,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isLoading: Boolean,
  },

  emits: ["update:modelValue", "add", "cancel"],

  data() {
    return {
      company: {
        name: "",
        website: "",
        webPresence: "",
        linkedInURL: "",
      },
    };
  },

  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },

      set(newModelValue: boolean) {
        this.$emit("update:modelValue", newModelValue);
      },
    },

    isAddButtonDisabled(): boolean {
      const { name, website, linkedInURL, webPresence } = this.company;

      const areAllInputsEmpty =
        isEmpty(website) && isEmpty(linkedInURL) && isEmpty(webPresence);

      const isInvalidInput =
        (!isEmpty(website) && !isValidUrl(website)) ||
        (!isEmpty(linkedInURL) && !isValidLinkedinUrl(linkedInURL)) ||
        (!isEmpty(webPresence) && !isValidUrl(webPresence));

      return isEmpty(name) || areAllInputsEmpty || isInvalidInput;
    },
  },

  watch: {
    modelValue() {
      this.company = {
        name: this.name,
        website: "",
        webPresence: "",
        linkedInURL: "",
      };
    },
  },

  methods: {
    handleAddClick() {
      this.$emit("add", this.company);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.add-company-modal__body {
  display: flex;
  flex-direction: column;
  gap: rem(30px);

  &__web-presence {
    padding: rem(15px) 0;
    display: flex;
    flex-direction: column;
    gap: rem(30px);

    :deep() {
      .company-info-section-item__section__container {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
}
</style>
