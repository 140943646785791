import router from "@/router";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";

export const redirect = (path: string) => {
  const { getNewTabEnabled } = useWebCustomerStore();

  if (getNewTabEnabled) {
    window.open(path, "_blank");
  } else {
    router.push(path);
  }
};
