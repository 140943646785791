import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "book-call-modal__message" }

import AppModal from "@/components/app/AppModal/AppModal.vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BookCallModal',
  props: {
    "modelValue": { required: true, type: Boolean },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const model = _useModel(__props, "modelValue");

const managers = [
  {
    name: "Andrea Canepa",
    avatar:
      "https://res.cloudinary.com/eutopia-3/image/upload/v1722340655/andrea_cpagcu.jpg",
    link: "https://meetings-eu1.hubspot.com/andrea-canepa/andrea-renewals",
  },
  {
    name: "Federico Cristoforoni",
    avatar:
      "https://res.cloudinary.com/eutopia-3/image/upload/v1722340655/federico_ij8ier.jpg",
    link: "https://meetings-eu1.hubspot.com/federico-cristoforoni/clone",
  },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    class: "book-call-modal",
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    size: "xsmall"
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("h3", { class: "modal-title" }, "Book a call with your account manager", -1)
    ])),
    body: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(managers, (manager) => {
        return _createElementVNode("div", {
          key: manager.name,
          class: "book-call-modal__manager"
        }, [
          _createVNode(AppIcon, {
            class: "book-call-modal__manager__avatar",
            alt: manager.name,
            src: manager.avatar,
            size: "100px"
          }, null, 8, ["alt", "src"]),
          _createElementVNode("p", _hoisted_1, _toDisplayString(manager.name), 1),
          _createVNode(AppButton, {
            class: "book-call-modal__manager__book-now",
            href: manager.link,
            label: "Book now"
          }, null, 8, ["href"])
        ])
      }), 64))
    ]),
    footer: _withCtx(({ hideModal }) => [
      _createVNode(AppButton, {
        label: "Cancel",
        type: "empty",
        onClick: hideModal
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})