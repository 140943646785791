<template>
  <div
    :class="['app-modal', { 'app-modal--hidden': !model }]"
    ref="appModal"
    @mousedown="handleAppModalClick"
    v-esc-keydown="hideModal"
  >
    <transition name="fade">
      <div
        v-if="model"
        :class="[
          'app-modal__content',
          {
            'app-modal__content--full': size === 'fullscreen',
          },
        ]"
      >
        <div class="app-modal__content__sidebar" v-if="$slots.sidebar">
          <slot name="sidebar" :hide-modal="hideModal" />
        </div>
        <div class="app-modal__content__container">
          <div
            v-if="$slots.header"
            :class="[
              'app-modal__content__header',
              {
                'app-modal__content__header--bordered': showHeaderBorder,
              },
            ]"
          >
            <slot name="header" :hide-modal="hideModal" />
          </div>
          <div class="app-modal__content__body" v-if="$slots.body">
            <slot name="body" :hide-modal="hideModal" />
          </div>
          <div v-if="$slots.footer" class="app-modal__content__footer">
            <slot name="footer" :hide-modal="hideModal" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from "vue";
import { AppModalSize } from "@/shared/types/components";
import { AppModalSizeEnum } from "@/shared/enums/components";

const model = defineModel<boolean>({ required: true });

const { size = "medium" } = defineProps<{
  size?: AppModalSize;
  showHeaderBorder?: boolean;
}>();

const appModal = useTemplateRef<HTMLDivElement>("appModal");

const getSize = computed(() => AppModalSizeEnum[size]);

const hideModal = () => {
  model.value = false;
};

const handleAppModalClick = (event: MouseEvent) => {
  if (event.target === appModal.value) {
    hideModal();
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-modal {
  display: flex;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $backdrop;
  overflow-y: auto;
  transition: 0.4s ease;

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }

  &__content {
    background-color: $white;
    margin: auto;
    border: rem(1px) solid $borderAlternative;
    border-radius: rem(16px);
    display: flex;
    transition: 0.4s ease;
    width: v-bind(getSize);

    &--full {
      height: 100vh;
      border-width: 0;
      border-radius: 0;
    }

    & > div:not(.app-modal__content__sidebar) {
      flex: 1;
      overflow: hidden;
    }

    &__header {
      padding: rem(25px) rem(25px) 0;

      &:deep(h1, h2, h3, h4, h5, h6) {
        margin: 0;
      }

      &--bordered {
        border-bottom: rem(1px) solid $border;
        padding-bottom: rem(20px);
      }
    }

    &__body {
      padding: rem(25px);
      overflow-y: auto;
    }

    &__footer {
      gap: rem(8px);
      display: flex;
      justify-content: flex-end;
      flex-flow: row wrap;
      padding: rem(15px) rem(25px);
      border-top: rem(1px) solid $border;
    }

    &__sidebar {
      padding: rem(36px) rem(15px);
      border-right: rem(1px) solid $border;
    }

    &__container {
      display: flex;
      flex-direction: column;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
