<template>
  <div
    :class="[
      'app-input',
      {
        'app-input--disabled': $attrs.disabled,
        'app-input--error': error,
      },
    ]"
  >
    <slot name="leading">
      <AppIcon v-if="leading" :src="leading" :size="leadingSize" />
    </slot>
    <input
      :value="model"
      v-bind="$attrs"
      :type="getType"
      data-testid="appInput"
      class="app-input__input"
      ref="input"
      @input="handleInput"
    />
    <slot name="trailing">
      <AppButton
        v-if="isPasswordType || isMasked"
        :icon="getPasswordIcon"
        color="#787878"
        padding="0"
        type="empty"
        @click="togglePasswordVisibility"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref, computed, useTemplateRef } from "vue";
import { AppInputType } from "@/shared/enums/components";

const AppIcon = defineAsyncComponent(
  () => import("@/components/app/AppIcon/AppIcon.vue"),
);
const AppButton = defineAsyncComponent(
  () => import("@/components/app/AppButton/AppButton.vue"),
);

const model = defineModel<string | number>();

const {
  isMasked,
  maxLength,
  borderRadius = "16px",
  type = AppInputType.TEXT,
} = defineProps<{
  borderRadius?: string;
  width?: string;
  error?: boolean;
  leading?: string;
  leadingSize?: string;
  type?: AppInputType;
  maxLength?: number;
  isMasked?: boolean;
}>();

const input = useTemplateRef<HTMLInputElement>("input");

const isPasswordVisible = ref(false);

const isPasswordType = computed(() => type === AppInputType.PASSWORD);

const getPasswordIcon = computed(() =>
  isPasswordVisible.value ? "show-password" : "hide-password",
);

const getType = computed((): AppInputType => {
  if (isPasswordType.value && isPasswordVisible.value) {
    return AppInputType.TEXT;
  }

  return type;
});

const getInputMaskedStyle = computed(() =>
  isMasked && !isPasswordVisible.value ? "disc" : "unset",
);

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

const handleInput = (e: Event) => {
  const { value } = e.target as HTMLInputElement;

  if (maxLength && String(value).length > maxLength) {
    input.value!.value = String(model.value);
  } else {
    model.value = value;
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-input {
  display: flex;
  gap: rem(8px);
  padding: rem(4px) rem(8px);
  border: rem(1px) solid $border;
  background-color: $white;
  align-items: center;
  width: v-bind(width);
  border-radius: v-bind(borderRadius);

  &:hover,
  &:focus-within {
    border-color: $primary;
  }

  &--error {
    border-color: $danger;
  }

  &--disabled {
    background-color: $disabled;
    border-color: $disabled;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    appearance: none;
    background: unset;
    -webkit-appearance: none;
    -webkit-text-security: v-bind(getInputMaskedStyle);

    &:autofill,
    &:-webkit-autofill:is(:hover, :focus, :active) {
      -webkit-box-shadow: 0 0 0 rem(30px) $white inset;
      box-shadow: 0 0 0 rem(30px) $white inset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
}
</style>
