import "@/shared/helpers/prototypes/String/string.extensions";
import "@/shared/helpers/prototypes/Number/number.extension";
import "@/styles/common.scss";
import VueApexCharts from "vue3-apexcharts";
import { createApp } from "vue";
import { clickOutside } from "@/directives/clickOutside/clickOutside";
import { escKeydown } from "@/directives/escKeydown/escKeydown";
import router from "@/router";
import store from "@/stores";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import * as Sentry from "@sentry/vue";
import App from "@/App.vue";

const webCustomerStore = useWebCustomerStore(store);

webCustomerStore
  .getLoggedWebCustomer()
  .catch(async () => {
    const currentRoute = window.location.href.replace(
      window.location.origin,
      "",
    );

    if (currentRoute !== "/login") {
      sessionStorage.setItem("redirectedFrom", currentRoute);
    }

    if (router.resolve(window.location.pathname).meta.layout) {
      await router.push(window.location.pathname);
    } else {
      await router.push("/login");
    }
  })
  .finally(() => {
    const app = createApp(App);

    Sentry.init({
      app,
      dsn: "https://35f11375b9189594147a85d5351558ae@o4508040685617152.ingest.de.sentry.io/4508040702722129",
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        /^https:\/\/api\.netzeroinsights\.com/,
        "20.108.20.67",
      ],
      profilesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: ["Network Error", "ECONNABORTED", "AbortError"],
    });

    app
      .use(router)
      .use(store)
      .use(VueApexCharts)
      .directive("click-outside", clickOutside)
      .directive("esc-keydown", escKeydown)
      .mount("#app");
  });
