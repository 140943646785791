import { RouteRecordRaw } from "vue-router";

export const searchPageRoutes: Array<RouteRecordRaw> = [
  {
    path: "/organizations",
    name: "Organizations",
    redirect: "/search",
  },
  {
    path: "/organizations/recently-raised",
    name: "SearchRecentlyRaised",
    redirect: "/search/recently-raised",
  },
  {
    path: "/organizations/recently-added",
    name: "SearchRecentlyAdded",
    redirect: "/search/recently-added",
  },
  {
    path: "/organizations/recently-viewed",
    name: "SearchRecentlyViewed",
    redirect: "/search/recently-viewed",
  },
  {
    path: "/organizations/new-organisations",
    name: "SearchNewOrganisations",
    redirect: "/search/new-organisations",
  },
  {
    path: "/organizations/top-funded",
    name: "SearchTopFunded",
    redirect: "/search/top-funded",
  },
  {
    path: "/organizations/tag/:id",
    name: "SearchTagSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/organizations/top-viewed",
    name: "Search<Most>Viewed",
    redirect: "/search/top-viewed",
  },
  {
    path: "/user/savedSearch/organizations/:filter/:acquisitionDateFrom?",
    name: "SearchEmailSavedSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/organizations/saved-search/:id",
    name: "OrganizationsSavedSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/organizations/added-last-thirty-days",
    name: "SearchAddedLastThirtyDays",
    redirect: "/search/added-last-thirty-days",
  },
  {
    path: "/search/added-last-thirty-days",
    name: "SearchAddedLastThirtyDays",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/similar-companies/:id",
    name: "OrganizationSimilarCompanies",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/lists/:id",
    name: "Portfolio",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/:token?",
    name: "SearchView",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },

  {
    path: "/search/recently-raised",
    name: "SearchRecentlyRaised",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/recently-added",
    name: "SearchRecentlyAdded",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/recently-viewed",
    name: "SearchRecentlyViewed",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/top-viewed",
    name: "SearchMostViewed",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/recent-deals",
    name: "RecentDeals",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/largest-deals",
    name: "LargestDeals",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/exits",
    name: "SearchExits",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/early",
    name: "SearchEarly",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/seed",
    name: "SearchSeed",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/late",
    name: "SearchLate",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/exit-deals",
    name: "SearchExitDeals",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/new-organisations",
    name: "SearchNewOrganisations",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/top-funded",
    name: "SearchTopFunded",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/investor-overview/:id",
    name: "SearchInvestorOverview",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/most-active-investors",
    name: "SearchMostActiveInvestors",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/co-investors/:id",
    name: "SearchCoInvestors",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/deals/investor/:id",
    name: "SearchDealsInvestor",
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/suggested-tag/:tag",
    name: "SuggestedTagSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/market-map/:cards*",
    name: "MarketMapSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/funding-rounds",
    name: "Search",
    redirect: "/search",
  },
  {
    path: "/funding-rounds/recent-deals",
    name: "SearchRecentDeals",
    redirect: "/search/recent-deals",
  },
  {
    path: "/funding-rounds/early",
    name: "SearchEarlyDeals",
    redirect: "/search/early",
  },
  {
    path: "/funding-rounds/late",
    name: "SearchLateDeals",
    redirect: "/search/late",
  },
  {
    path: "/funding-rounds/seed",
    name: "SearchSeedDeals",
    redirect: "/search/seed",
  },
  {
    path: "/funding-rounds/exit-deals",
    name: "SearchExitsDeals",
    redirect: "/search/exit-deals",
  },
  {
    path: "/funding-rounds/largest-deals",
    name: "SearchLargestDeals",
    redirect: "/search/largest-deals",
  },
  {
    path: "/funding-rounds/exits",
    name: "SearchExits",
    redirect: "/search/exits",
  },
  {
    path: "/user/savedSearch/funding-rounds/:filter/:roundDateFrom?/:acquisitionDateFrom?",
    name: "FundingRoundEmailSavedSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/tagSearch/:tag+",
    name: "SearchTagSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/taxonomy-item/:item",
    name: "TaxonomyItemSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/financingInstrumentSearch/:instrument/:start/:end",
    name: "SearchFinancingInstrumentSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/equityStageSearch/:stage/:start/:end",
    name: "SearchEquityStageSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/fundingRangeSearch/:funding/:start/:end",
    name: "SearchFundingRangeSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/funding-rounds/saved-search/:id",
    name: "DealsSavedSearch",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/investors/saved-search/:id",
    name: "InvestorsSavedSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/recent/:type/:id",
    name: "RecentSearch",
    component: () => import("@/views/SearchView/SearchView.vue"),
    props: true,
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/last-twelve-months",
    name: "SearchLastTwelveMonths",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/last-thirty-days",
    name: "SearchLastThirtyDays",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/historical-funding-ytd",
    name: "SearchHistoricalFundingYTD",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
  {
    path: "/search/historical-deals-last-seven-days",
    name: "SearchHistoricalDealsLastSevenDays",
    props: true,
    component: () => import("@/views/SearchView/SearchView.vue"),
    meta: {
      title: "Search | Net Zero Insights",
    },
  },
];
