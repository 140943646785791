<template>
  <div
    class="app-floating-menu"
    ref="appFloatingMenu"
    v-click-outside="handleCloseMenu"
  >
    <slot name="content" />
    <Transition
      enter-active-class="animate-in"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="animate-out"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <ul
        :class="`app-floating-menu-list app-floating-menu-list--${side}`"
        :style="appFloatingMenuListStyle"
        v-if="isOpen"
      >
        <AppFloatingMenuItem
          v-for="option in options"
          :item="option"
          :key="'app-floating-menu-list-option-' + option.name"
          @click="handleOptionClick(option)"
        />
      </ul>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from "vue";
import type { StyleValue } from "vue";
import type {
  AppFloatingMenuOption,
  AppFloatingMenuSide,
} from "@/shared/types/components";
import AppFloatingMenuItem from "@/components/app/AppFloatingMenuItem/AppFloatingMenuItem.vue";

const isOpen = defineModel<boolean>();

const { side = "right" } = defineProps<{
  options: AppFloatingMenuOption[];
  side?: AppFloatingMenuSide;
}>();

const emit = defineEmits<{
  select: [option: AppFloatingMenuOption];
}>();

const appFloatingMenu = useTemplateRef<HTMLDivElement>("appFloatingMenu");

const appFloatingMenuListStyle = computed((): StyleValue => {
  return {
    left: side === "left" ? 0 : undefined,
    right: side === "right" ? 0 : undefined,
    top: `${(appFloatingMenu.value?.clientHeight || 20) + 4}px`,
  };
});

const handleCloseMenu = () => {
  if (isOpen.value) {
    isOpen.value = false;
  }
};

const handleOptionClick = (option: AppFloatingMenuOption) => {
  if (!option.isDisabled) {
    emit("select", option);
    handleCloseMenu();
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-floating-menu {
  position: relative;
  width: fit-content;
}

.app-floating-menu-list {
  background-color: $white;
  border: 1px solid $border;
  border-radius: rem(16px);
  position: absolute;
  top: rem(8px);
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: max-content;
  z-index: 10;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.app-floating-menu-list--right,
.app-floating-menu-list--left {
  border-radius: rem(16px);
}

.animate-in {
  animation: enter 100ms ease-out;
  transform-origin: top;
}

.animate-out {
  animation: leave 100ms ease-in;
  transform-origin: top;
}

@keyframes enter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes leave {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
</style>
