import { Currency, SalesforceAuthDetails } from "@/shared/types/common";
import axios from "@/plugins/axios/axios";
import { AxiosResponse } from "axios";
import { TeamMember } from "@/shared/types/profileClaim";

const getTeamMemberEmails = (
  teamId: number,
): Promise<AxiosResponse<TeamMember[]>> => {
  return axios.get(`/teams/teamMembers/${teamId}`);
};

const setLimit = (pageSize: number) => {
  return axios.put("/webcustomers/pageSize", { pageSize });
};

const setCurrency = (currency: Currency) => {
  return axios.put("/webcustomers/selectedCurrency", { currency });
};

const toggleOpenInNewTab = (): Promise<AxiosResponse<boolean>> => {
  return axios.put("/webcustomers/newTab");
};

const removeAffinityKey = (): Promise<AxiosResponse<boolean>> => {
  return axios.delete("/affinity/removeAPIKey");
};

const updateAffinityKey = (apiKey: string): Promise<AxiosResponse<boolean>> => {
  return axios.post("/affinity/checkAndUpdateAPIKey", { value: apiKey });
};

const getSalesforceAuth = (): Promise<AxiosResponse<SalesforceAuthDetails>> => {
  return axios.get("/salesforceAuth");
};

const updateSalesforceAuth = (data: SalesforceAuthDetails) => {
  return axios.post("/salesforceAuth", data);
};

const removeSalesforceAuth = (
  webCustomerId: number,
): Promise<AxiosResponse<boolean>> => {
  return axios.delete(`/salesforceAuth/${webCustomerId}`);
};

function toggleTwoFactorAuth(
  webCustomerID: number,
): Promise<AxiosResponse<boolean>> {
  return axios.put(`/2fa/${webCustomerID}`);
}

export default {
  getTeamMemberEmails,
  setLimit,
  setCurrency,
  toggleOpenInNewTab,
  removeAffinityKey,
  updateAffinityKey,
  getSalesforceAuth,
  updateSalesforceAuth,
  removeSalesforceAuth,
  toggleTwoFactorAuth,
};
