import { setBlockTracking as _setBlockTracking, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-toast__label" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = _createElementVNode("div", {
      class: _normalizeClass(`app-toast app-toast--${_ctx.type}`)
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
      _createElementVNode("span", {
        class: "app-toast__button",
        onClick: _withModifiers(_ctx.removeClick, ["stop"])
      }, "×", 8, _hoisted_2)
    ], 2)).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}