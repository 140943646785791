// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Number {
  toEuro(): string;
  toDollar(): string;
}

Number.prototype.toEuro = function (): string {
  if (this !== null && this !== undefined && !Number.isNaN(this)) {
    return `€ ${this}`;
  }

  return `${this}`;
};

Number.prototype.toDollar = function (): string {
  if (this !== null && this !== undefined && !Number.isNaN(this)) {
    return `$ ${this}`;
  }

  return `${this}`;
};
