import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "app-layout__navigation-bar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "app-layout__navigation-bar__actions" }
const _hoisted_5 = { class: "app-layout__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppExpiryBanner = _resolveComponent("AppExpiryBanner")!
  const _component_AppLink = _resolveComponent("AppLink")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppTooltip = _resolveComponent("AppTooltip")!
  const _component_AppNewCompanyModal = _resolveComponent("AppNewCompanyModal")!
  const _component_AppFloatingMenu = _resolveComponent("AppFloatingMenu")!
  const _component_BookCallModal = _resolveComponent("BookCallModal")!
  const _component_AppSidebar = _resolveComponent("AppSidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.webCustomerStore.showSubscriptionBanner)
      ? (_openBlock(), _createBlock(_component_AppExpiryBanner, {
          key: 0,
          onOpenBookCallModal: _ctx.handleOpenBookCallModal
        }, null, 8, ["onOpenBookCallModal"]))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_AppLink, {
        to: "/dashboard",
        "link-component": "router-link",
        class: "app-layout__navigation-bar__brand"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.netZeroInsightsLogoHighRes,
            alt: "Net Zero Insights",
            class: "app-layout__navigation-bar__brand__logo"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        autocomplete: "off"
      }, [
        _createVNode(_component_AppInput, {
          leading: _ctx.magnifierGrey,
          placeholder: "Search...",
          autocomplete: "off",
          width: "400px",
          modelValue: _ctx.searchResultsText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchResultsText) = $event)),
          modelModifiers: { trim: true }
        }, null, 8, ["leading", "modelValue"])
      ], 32),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.webCustomer.profileClaimToken)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              label: "Edit company profile",
              size: "small",
              to: _ctx.claimProfileURL,
              "font-size": "12px",
              height: "30px"
            }, null, 8, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.webCustomer.preview)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 1,
              href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
              label: "Upgrade",
              size: "small",
              "font-size": "12px",
              height: "30px",
              class: "app-layout__navigation-bar__actions__upgrade"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_AppTooltip, { content: "Add an organisation to the Net0 Platform" }, {
          default: _withCtx(() => [
            _createVNode(_component_AppButton, {
              icon: _ctx.plusIconGreen,
              type: "empty",
              padding: "0",
              onClick: _ctx.handleAddCompanyClick
            }, null, 8, ["icon", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_AppNewCompanyModal, {
          modelValue: _ctx.showAddCompanyModal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAddCompanyModal) = $event)),
          "is-loading": _ctx.isLoading,
          name: _ctx.searchText,
          onAdd: _ctx.handleAddNewCompany
        }, null, 8, ["modelValue", "is-loading", "name", "onAdd"]),
        _createVNode(_component_AppFloatingMenu, {
          modelValue: _ctx.appFloatingMenuModel,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.appFloatingMenuModel) = $event)),
          options: _ctx.appFloatingMenuOptions,
          side: "right"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", {
              class: "app-layout__navigation-bar__actions__user",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleAppFloatingMenuClick && _ctx.handleAppFloatingMenuClick(...args)))
            }, _toDisplayString(_ctx.userInitials), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "options"])
      ])
    ]),
    _createVNode(_component_BookCallModal, {
      modelValue: _ctx.bookCallModal,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bookCallModal) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("main", _hoisted_5, [
      _createVNode(_component_AppSidebar, { links: _ctx.links }, null, 8, ["links"]),
      _createElementVNode("div", {
        class: "app-layout__body__content",
        ref: "app-content",
        style: _normalizeStyle(_ctx.contentStyle)
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "app-layout__body__content__header-line" }, null, -1)),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ])
  ]))
}