import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "company-info-section-item" }
const _hoisted_2 = { class: "company-info-section-item__section__container" }
const _hoisted_3 = { class: "company-info-section-item__section__content" }
const _hoisted_4 = { class: "company-info-section-item__section" }
const _hoisted_5 = { class: "company-info-section-item__section__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInformation = _resolveComponent("AppInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'company-info-section-item__section',
        { 'company-info-section-item__section--center': _ctx.isAlignCenter },
        { 'company-info-section-item__section--column': _ctx.isColumn },
      ])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", {
          class: _normalizeClass([
            'company-info-section-item__section__title',
            { 'required-field': _ctx.isRequired },
          ])
        }, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ], 2),
        (_ctx.information)
          ? (_openBlock(), _createBlock(_component_AppInformation, {
              key: 0,
              content: _ctx.information
            }, null, 8, ["content"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "company-info-section-item__section__container" }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "append", {}, undefined, true)
      ])
    ])
  ]))
}