import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppTooltip = _resolveComponent("AppTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTooltip, {
      content: _ctx.content,
      "allow-html": _ctx.allowHtml
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppIcon, {
          src: _ctx.information,
          size: _ctx.size
        }, null, 8, ["src", "size"])
      ]),
      _: 1
    }, 8, ["content", "allow-html"])
  ]))
}