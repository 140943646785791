<template>
  <div class="unauthorized-view">
    <AppLink href="https://netzeroinsights.com" class="unauthorized-view__logo">
      <img
        :src="netZeroInsightsLogoHighRes"
        alt="Net Zero Insights logo"
        class="unauthorized-view__logo"
    /></AppLink>
    <div class="unauthorized-view__content">
      <router-view />
    </div>
    <span>
      Need help? Contact us at
      <AppLink
        class="unauthorized-view__footer__contact"
        :href="`mailto:${shownEmailAddress}`"
      >
        {{ shownEmailAddress }}
      </AppLink>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { netZeroInsightsLogoHighRes } from "@/shared/constants/icons";
import AppLink from "@/components/app/AppLink/AppLink.vue";

export default defineComponent({
  name: "UnauthorizedView",

  components: { AppLink },

  data() {
    return {
      netZeroInsightsLogoHighRes,
    };
  },

  computed: {
    shownEmailAddress(): string {
      if (this.$route.name === "ClaimProfileInbound") {
        return "ashwini@netzeroinsights.com";
      } else {
        return "info@netzeroinsights.com";
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.unauthorized-view {
  justify-content: space-between;
  background-color: $background;
  flex-direction: column;
  box-sizing: border-box;
  padding: rem(24px) rem(84px);
  display: flex;
  height: 100vh;
}

.unauthorized-view__logo {
  width: rem(200px);
  height: auto;
}

.unauthorized-view__content {
  width: fit-content;
  align-self: center;
  display: flex;
}

.unauthorized-view__footer__contact {
  color: $primary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
