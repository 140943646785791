<template>
  <AppModal class="app-free-trial-modal" v-model="model" size="small">
    <template #header>
      <h3 class="modal-title">{{ title }}</h3>
    </template>
    <template #body>
      <p class="app-free-trial-modal__message">
        {{ message }}
      </p>
    </template>
    <template #footer="{ hideModal }">
      <AppButton label="Close" type="empty" @click="hideModal" />
      <AppButton
        href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
        label="Upgrade now"
      />
    </template>
  </AppModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";

export default defineComponent({
  name: "AppFreeTrialModal",

  components: {
    AppModal,
    AppButton,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-free-trial-modal {
  z-index: 11;

  &__message {
    margin-top: 0;
  }
}
</style>
