import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubmitInfoModal',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
  get() {
    return props.modelValue;
  },
  set(val: boolean) {
    emit("update:modelValue", val);
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    "show-header-border": ""
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("h3", { class: "modal-title" }, "Why should I submit my info?", -1)
    ])),
    body: _withCtx(() => _cache[2] || (_cache[2] = [
      _createTextVNode(" Being a part of the Net0 Platform is a critical stride towards accelerating your startup's success in the climate tech landscape. "),
      _createElementVNode("br", null, null, -1),
      _createElementVNode("br", null, null, -1),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, " Gain unmatched visibility among top-tier investors & unlock exclusive advantages for fundraising and growth. "),
        _createElementVNode("li", null, " Get full access to a vast climate tech investor database to propel your fundraising efforts. "),
        _createElementVNode("li", null, " Become eligible for exclusive coupons & rewards designed to upscale your business. ")
      ], -1)
    ])),
    footer: _withCtx(({ hideModal }) => [
      _createVNode(AppButton, {
        label: "GOT IT",
        type: "empty",
        onClick: hideModal
      }, null, 8, ["onClick"]),
      _createVNode(AppButton, {
        label: "Learn more",
        href: "https://netzeroinsights.com/register-on-the-net0-platform/"
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})