import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-title" }
const _hoisted_2 = { class: "app-free-trial-modal__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    class: "app-free-trial-modal",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    size: "small"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1)
    ]),
    footer: _withCtx(({ hideModal }) => [
      _createVNode(_component_AppButton, {
        label: "Close",
        type: "empty",
        onClick: hideModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_AppButton, {
        href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
        label: "Upgrade now"
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}