<template>
  <div class="app-information">
    <AppTooltip :content="content" :allow-html="allowHtml">
      <AppIcon :src="information" :size="size" />
    </AppTooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { information } from "@/shared/constants/icons";
import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";

export default defineComponent({
  name: "AppInformation",

  components: { AppIcon, AppTooltip },

  props: {
    content: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "12px",
    },
    allowHtml: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      information,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";

.app-information {
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: rem(2px);
}
</style>
