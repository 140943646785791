<template>
  <img v-bind="bind" :style="style" :class="appIconClass" />
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from "vue";

export default defineComponent({
  name: "AppIcon",

  props: {
    src: {
      type: String as PropType<string | ArrayBuffer>,
      default: undefined,
    },
    size: {
      type: String,
      default: "16px",
    },
    borderRadius: {
      type: String,
      default: undefined,
    },
    padding: {
      type: String,
      default: undefined,
    },
    bordered: Boolean,
  },

  computed: {
    bind() {
      return {
        src: this.src,
        alt: this.src,
      };
    },

    style(): StyleValue {
      return {
        width: this.size,
        height: this.size,
        borderRadius: this.borderRadius,
        padding: this.padding,
      };
    },

    appIconClass() {
      return ["app-icon", { "app-icon--bordered": this.bordered }];
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.app-icon {
  object-fit: contain;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
}

.app-icon--bordered {
  border: 1px solid $border;
}
</style>
