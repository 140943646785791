import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "app-expiry-banner" }
const _hoisted_2 = {
  key: 0,
  class: "app-expiry-banner__content"
}
const _hoisted_3 = {
  key: 1,
  class: "app-expiry-banner__content"
}

import { computed } from "vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppButton from "@/components/app/AppButton/AppButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppExpiryBanner',
  emits: ["open-book-call-modal"],
  setup(__props, { emit: __emit }) {

const { webCustomer } = useWebCustomerStore();

const emit = __emit;

const { freeTrial } = webCustomer;

const subscriptionEndDate = computed(() => {
  const webCustomerEndDate = new Date(webCustomer.endDate);

  const month = (webCustomerEndDate.getMonth() + 1).toString().padStart(2, "0");

  const day = webCustomerEndDate.getDate().toString().padStart(2, "0");

  return `${webCustomerEndDate.getFullYear()}-${month}-${day} `;
});

const handleOpenBookCallModal = () => {
  emit("open-book-call-modal");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(freeTrial))
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(`Your trial account will expire on ${subscriptionEndDate.value}`), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(`Your subscription will expire on ${subscriptionEndDate.value}. If you want to renew it,`) + " ", 1),
          _createVNode(AppButton, {
            class: "app-expiry-banner__content__book-call",
            onClick: handleOpenBookCallModal,
            padding: "0",
            type: "empty"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" book a call ")
            ])),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createTextVNode(" with your account manager "))
        ]))
  ]))
}
}

})