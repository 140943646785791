import { Currency } from "@/shared/types/common";

export function formatCurrencyHuman(
  value: number,
  digits: number,
  currency?: string,
): string {
  const currencySign = currency ? (currency === Currency.EUR ? "€" : "$") : "";

  if (!value) {
    return `${currencySign}0`;
  } else {
    const isNegative = value < 0;
    const absValue = Math.abs(value);

    const currenciesTable = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];

    const regExp = /\.0+$|(\.[0-9]*[1-9])0+$/;

    const item = currenciesTable
      .slice()
      .reverse()
      .find((item) => absValue >= item.value);

    const finalValue = item
      ? (absValue / item.value).toFixed(digits).replace(regExp, "$1") +
        item.symbol
      : "0";

    return `${isNegative ? "-" : ""}${currencySign}${finalValue}`;
  }
}
