<template>
  <div class="app-expiry-banner">
    <p v-if="freeTrial" class="app-expiry-banner__content">
      {{ `Your trial account will expire on ${subscriptionEndDate}` }}
    </p>
    <template v-else>
      <p class="app-expiry-banner__content">
        {{
          `Your subscription will expire on ${subscriptionEndDate}. If you want to renew it,`
        }}
        <AppButton
          class="app-expiry-banner__content__book-call"
          @click="handleOpenBookCallModal"
          padding="0"
          type="empty"
        >
          book a call
        </AppButton>
        with your account manager
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppButton from "@/components/app/AppButton/AppButton.vue";

const { webCustomer } = useWebCustomerStore();

const emit = defineEmits<{
  (event: "open-book-call-modal"): void;
}>();

const { freeTrial } = webCustomer;

const subscriptionEndDate = computed(() => {
  const webCustomerEndDate = new Date(webCustomer.endDate);

  const month = (webCustomerEndDate.getMonth() + 1).toString().padStart(2, "0");

  const day = webCustomerEndDate.getDate().toString().padStart(2, "0");

  return `${webCustomerEndDate.getFullYear()}-${month}-${day} `;
});

const handleOpenBookCallModal = () => {
  emit("open-book-call-modal");
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-expiry-banner {
  position: fixed;
  z-index: 11;
  height: rem(32px);
  width: 100%;
  background-color: $hover;
  overflow: hidden;
  flex-shrink: 0;

  &__content {
    text-align: center;
    color: $black;
    font-weight: 500;
    line-height: rem(16px);

    &__book-call {
      display: inline;
      font-weight: 500;
      color: $black;
      text-decoration-line: underline;
    }
  }
}
</style>
