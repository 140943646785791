import { useModel as _useModel, useCssVars as _useCssVars, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "type"]

import { defineAsyncComponent, ref, computed, useTemplateRef } from "vue";
import { AppInputType } from "@/shared/enums/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppInput',
  props: /*@__PURE__*/_mergeModels({
    borderRadius: { default: "16px" },
    width: {},
    error: { type: Boolean },
    leading: {},
    leadingSize: {},
    type: { default: () => (AppInputType.TEXT) },
    maxLength: {},
    isMasked: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

_useCssVars(_ctx => ({
  "a6b3a9d8": (_ctx.width),
  "8ecfea60": (_ctx.borderRadius),
  "039c3600": (getInputMaskedStyle.value)
}))

const AppIcon = defineAsyncComponent(
  () => import("@/components/app/AppIcon/AppIcon.vue"),
);
const AppButton = defineAsyncComponent(
  () => import("@/components/app/AppButton/AppButton.vue"),
);

const model = _useModel<string | number>(__props, "modelValue");



const input = useTemplateRef<HTMLInputElement>("input");

const isPasswordVisible = ref(false);

const isPasswordType = computed(() => __props.type === AppInputType.PASSWORD);

const getPasswordIcon = computed(() =>
  isPasswordVisible.value ? "show-password" : "hide-password",
);

const getType = computed((): AppInputType => {
  if (isPasswordType.value && isPasswordVisible.value) {
    return AppInputType.TEXT;
  }

  return __props.type;
});

const getInputMaskedStyle = computed(() =>
  __props.isMasked && !isPasswordVisible.value ? "disc" : "unset",
);

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

const handleInput = (e: Event) => {
  const { value } = e.target as HTMLInputElement;

  if (__props.maxLength && String(value).length > __props.maxLength) {
    input.value!.value = String(model.value);
  } else {
    model.value = value;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'app-input',
      {
        'app-input--disabled': _ctx.$attrs.disabled,
        'app-input--error': _ctx.error,
      },
    ])
  }, [
    _renderSlot(_ctx.$slots, "leading", {}, () => [
      (_ctx.leading)
        ? (_openBlock(), _createBlock(_unref(AppIcon), {
            key: 0,
            src: _ctx.leading,
            size: _ctx.leadingSize
          }, null, 8, ["src", "size"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("input", _mergeProps({ value: model.value }, _ctx.$attrs, {
      type: getType.value,
      "data-testid": "appInput",
      class: "app-input__input",
      ref_key: "input",
      ref: input,
      onInput: handleInput
    }), null, 16, _hoisted_1),
    _renderSlot(_ctx.$slots, "trailing", {}, () => [
      (isPasswordType.value || _ctx.isMasked)
        ? (_openBlock(), _createBlock(_unref(AppButton), {
            key: 0,
            icon: getPasswordIcon.value,
            color: "#787878",
            padding: "0",
            type: "empty",
            onClick: togglePasswordVisibility
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})