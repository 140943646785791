import { SavedSearchType } from "@/shared/types/savedSearches";

export const SAVED_SEARCHES_URLS = {
  [SavedSearchType.COMPANIES]: "organizations",
  [SavedSearchType.FUNDING_ROUNDS]: "funding-rounds",
  [SavedSearchType.INVESTORS]: "investors",
};

export const SAVED_SEARCHES_TABS = {
  [SavedSearchType.COMPANIES]: "organizations",
  [SavedSearchType.FUNDING_ROUNDS]: "fundingRounds",
  [SavedSearchType.INVESTORS]: "investors",
};
