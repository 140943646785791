import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import { computed, useTemplateRef } from "vue";
import type { StyleValue } from "vue";
import type {
  AppFloatingMenuOption,
  AppFloatingMenuSide,
} from "@/shared/types/components";
import AppFloatingMenuItem from "@/components/app/AppFloatingMenuItem/AppFloatingMenuItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFloatingMenu',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    side: { default: "right" }
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["select"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const isOpen = _useModel<boolean>(__props, "modelValue");



const emit = __emit;

const appFloatingMenu = useTemplateRef<HTMLDivElement>("appFloatingMenu");

const appFloatingMenuListStyle = computed((): StyleValue => {
  return {
    left: __props.side === "left" ? 0 : undefined,
    right: __props.side === "right" ? 0 : undefined,
    top: `${(appFloatingMenu.value?.clientHeight || 20) + 4}px`,
  };
});

const handleCloseMenu = () => {
  if (isOpen.value) {
    isOpen.value = false;
  }
};

const handleOptionClick = (option: AppFloatingMenuOption) => {
  if (!option.isDisabled) {
    emit("select", option);
    handleCloseMenu();
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "app-floating-menu",
    ref_key: "appFloatingMenu",
    ref: appFloatingMenu
  }, [
    _renderSlot(_ctx.$slots, "content"),
    _createVNode(_Transition, {
      "enter-active-class": "animate-in",
      "enter-from-class": "opacity-0 scale-95",
      "enter-to-class": "opacity-100 scale-100",
      "leave-active-class": "animate-out",
      "leave-from-class": "opacity-100 scale-100",
      "leave-to-class": "opacity-0 scale-95"
    }, {
      default: _withCtx(() => [
        (isOpen.value)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 0,
              class: _normalizeClass(`app-floating-menu-list app-floating-menu-list--${_ctx.side}`),
              style: _normalizeStyle(appFloatingMenuListStyle.value)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createBlock(AppFloatingMenuItem, {
                  item: option,
                  key: 'app-floating-menu-list-option-' + option.name,
                  onClick: ($event: any) => (handleOptionClick(option))
                }, null, 8, ["item", "onClick"]))
              }), 128))
            ], 6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_outside, handleCloseMenu]
  ])
}
}

})