import { ref } from "vue";
import {
  OrganizationSearchResult,
  UniversalSavedSearchResult,
  UniversalSearchResult,
  UniversalSearchResultItem,
  UniversalSearchResultItems,
  UniversalSearchResultKey,
} from "@/shared/types/organization";
import { SAVED_SEARCHES_URLS } from "@/shared/constants/saved-searches";
import { TaxonomySearchResult } from "@/shared/types/taxonomy";
import { InvestorSearchResult } from "@/shared/types/investor";
import { FundingRound } from "@/shared/types/common";

const searchResultsText = ref("");

export const useSearchResults = (isSearchbar?: boolean) => {
  const universalSearchResultLabels = {
    [UniversalSearchResultKey.COMPANIES]: isSearchbar ? "Company" : "Companies",
    [UniversalSearchResultKey.PORTFOLIOS]: isSearchbar ? "List" : "Lists",
    [UniversalSearchResultKey.INVESTORS]: isSearchbar
      ? "Investor"
      : "Investors",
    [UniversalSearchResultKey.MARKET_RESEARCH]: isSearchbar
      ? "Report"
      : "Market Research",
    [UniversalSearchResultKey.SAVED_SEARCHES]: isSearchbar
      ? "Saved Search"
      : "Saved Searches",
    [UniversalSearchResultKey.TAXONOMY_ITEMS]: "Taxonomy",
    [UniversalSearchResultKey.DEALS]: isSearchbar ? "Deal" : "Deals",
  };

  const getOptionsByOrder = (
    searchResult: UniversalSearchResult,
  ): UniversalSearchResult => {
    const order = {
      [UniversalSearchResultKey.COMPANIES]: [],
      [UniversalSearchResultKey.INVESTORS]: [],
      [UniversalSearchResultKey.DEALS]: [],
      [UniversalSearchResultKey.TAXONOMY_ITEMS]: [],
      [UniversalSearchResultKey.MARKET_RESEARCH]: [],
      [UniversalSearchResultKey.PORTFOLIOS]: [],
      [UniversalSearchResultKey.SAVED_SEARCHES]: [],
    };

    const reordered = {} as UniversalSearchResult;

    (Object.keys(order) as Array<keyof UniversalSearchResult>).forEach(
      (key) => {
        if (searchResult[key]) {
          reordered[key] = searchResult[key] as (typeof order)[typeof key];
        }
      },
    );

    return reordered;
  };

  const getName = (item: UniversalSearchResultItems) => {
    if ("name" in item) {
      return item.name;
    } else if ("label" in item) {
      return item.label;
    } else {
      return item.clientName;
    }
  };

  const getLink = (
    key: UniversalSearchResultKey,
    item: UniversalSearchResultItems,
  ): string => {
    switch (key) {
      case UniversalSearchResultKey.TAXONOMY_ITEMS:
        return `/taxonomy${(item as TaxonomySearchResult).url}`;
      case UniversalSearchResultKey.SAVED_SEARCHES: {
        const savedSearchItem = item as UniversalSavedSearchResult;
        return `/${SAVED_SEARCHES_URLS[savedSearchItem.searchType]}/saved-search/${savedSearchItem.id}`;
      }
      case UniversalSearchResultKey.INVESTORS:
        return `/investor/${(item as InvestorSearchResult).directURL}`;
      case UniversalSearchResultKey.COMPANIES:
        return `/organization/${(item as OrganizationSearchResult).directURL}`;
      case UniversalSearchResultKey.DEALS:
        return `/organization/${(item as FundingRound).clientId}`;
      case UniversalSearchResultKey.MARKET_RESEARCH:
        return `/market-research/${(item as UniversalSearchResultItem).id}`;
      case UniversalSearchResultKey.PORTFOLIOS:
        return `/lists/${(item as UniversalSearchResultItem).id}`;
      default:
        return "";
    }
  };

  const clearSearchResultsText = () => {
    searchResultsText.value = "";
  };

  return {
    searchResultsText,
    universalSearchResultLabels,
    getOptionsByOrder,
    getLink,
    getName,
    clearSearchResultsText,
  };
};
