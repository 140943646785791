<template>
  <aside
    :class="['app-sidebar', { 'app-sidebar--expanded': isSidebarHovered }]"
    @mouseover="handleSidebarMouseOver"
    @mouseleave="handleSidebarMouseLeave"
  >
    <ul class="app-sidebar__list">
      <AppSidebarItem
        v-for="item in links"
        :key="item.name"
        :item="item"
        :is-sidebar-collapsed="isSidebarHovered"
      />
    </ul>
  </aside>
</template>

<script lang="ts">
import { AppSidebarLink } from "@/shared/types/components";
import { defineComponent, PropType } from "vue";
import { mapStores } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppSidebarItem from "@/components/app/AppSidebarItem/AppSidebarItem.vue";

export default defineComponent({
  name: "AppSidebar",
  components: { AppSidebarItem },
  props: {
    links: {
      type: Array as PropType<AppSidebarLink[]>,
      required: true,
    },
  },

  data: () => ({
    isSidebarHovered: false,
    navbarHeight: 64,
    expiryBannerHeight: 32,
    sidebarHeightDifference: "64px",
  }),

  computed: {
    ...mapStores(useWebCustomerStore),
  },

  methods: {
    handleSidebarMouseOver() {
      if (!this.isSidebarHovered) {
        this.isSidebarHovered = true;
      }
    },
    handleSidebarMouseLeave() {
      if (this.isSidebarHovered) {
        this.isSidebarHovered = false;
      }
    },
  },
  watch: {
    "webCustomerStore.showSubscriptionBanner": {
      immediate: true,
      handler(showSubscriptionBanner) {
        if (showSubscriptionBanner) {
          this.sidebarHeightDifference = `${this.navbarHeight + this.expiryBannerHeight}px`;
        } else {
          this.sidebarHeightDifference = `${this.navbarHeight}px`;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/styles/colors";
@import "src/styles/functions";

.app-sidebar {
  display: flex;
  height: calc(100vh - v-bind(sidebarHeightDifference));
  bottom: 0;
  flex-direction: column;
  min-width: rem(48px);
  max-width: rem(48px);
  background-color: $white;
  width: fit-content;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  padding: rem(24px) 0;
  overflow: auto;
  gap: rem(24px);

  &--expanded {
    min-width: rem(164px);
    max-width: unset;
    box-shadow: 0 rem(8px) rem(16px) $shadow;
    border-right: 1px solid $border;
    z-index: 999;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: rem(10px);
  }
}
</style>
