<template>
  <component v-if="iconComponent" :is="iconComponent" v-bind="binding" />
  <span class="base-icon__reserve" v-else v-bind="binding" />
</template>

<script setup lang="ts">
import { computed, shallowRef, watchEffect } from "vue";
import { defineAsyncComponent } from "vue";

const props = defineProps<{
  icon: string;
  size?: string;
}>();

const iconComponent = shallowRef(null);

const binding = computed(() => {
  if (props.size) {
    const [width, height] = props.size.split(" ");

    return { width, height: height || width };
  }

  return {};
});

watchEffect(async () => {
  if (props.icon) {
    iconComponent.value = await defineAsyncComponent(
      () => import(`@/components/icons/${props.icon}-icon.vue`),
    );
  }
});
</script>
