import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import { AppButtonType } from "@/shared/types/components";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";
import { ref, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
    iconSize: { default: "16px" },
    trailingIconSize: { default: "16px" },
    padding: { default: "6px 16px" },
    fontSize: {},
    fontWeight: { default: "300" },
    width: { default: "fit-content" },
    height: {},
    icon: {},
    label: {},
    color: {},
    iconHover: {},
    isLoading: { type: Boolean },
    isDisabled: { type: Boolean },
    type: { default: "primary" },
    nativeType: { default: "button" },
    trailingIcon: {},
    trailingIconHover: {},
    shiftTrailingIconOnHover: { type: Boolean },
    to: {},
    href: {},
    targetBlank: { type: Boolean },
    labelClass: {},
    iconClass: {},
    loadingText: { default: "Loading..." }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "8c3be490": (_ctx.width),
  "6890af75": (_ctx.height),
  "499be783": (_ctx.padding),
  "5cd73884": (_ctx.fontSize),
  "76c46a16": (_ctx.fontWeight)
}))

const { getNewTabEnabled } = useWebCustomerStore();

const props = __props;

const isHovered = ref(false);

const buttonLabel = computed((): string | undefined => {
  return props.isLoading ? props.loadingText : props.label;
});

const isLink = computed((): boolean => {
  return !!props.to || !!props.href;
});

const buttonComponent = computed(() => {
  if (isLink.value) {
    let target: "_blank" | "_self" = "_self";

    if (
      props.targetBlank ||
      isExternalLink(props.href || props.to || "") ||
      getNewTabEnabled
    ) {
      target = "_blank";
    }

    if (props.href) {
      return {
        component: "a",
        bind: {
          href: props.href,
          target,
        },
      };
    } else {
      return {
        component: "router-link",
        bind: {
          to: props.to,
          target,
        },
      };
    }
  } else {
    return {
      component: "button",
      bind: {},
    };
  }
});

//TODO: remove this after completing update everywhere
const iconSrc = computed((): string | undefined => {
  return isHovered.value && !props.isDisabled && props.iconHover
    ? props.iconHover
    : props.icon;
});

const trailingIconSrc = computed((): string | undefined => {
  return isHovered.value && !props.isDisabled && props.trailingIconHover
    ? props.trailingIconHover
    : props.trailingIcon;
});

const isIconImported = (icon: string) =>
  icon?.startsWith("data:image/png") ||
  icon?.startsWith("/img/") ||
  icon?.includes("http");

const setIsHover = (value: boolean) => {
  isHovered.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(buttonComponent.value.component), _mergeProps({
    disabled: _ctx.isDisabled || _ctx.isLoading,
    type: _ctx.nativeType,
    class: [
      'app-button',
      `app-button--${_ctx.isLoading || _ctx.isDisabled ? 'disabled' : _ctx.type}`,
    ]
  }, { ..._ctx.$attrs, ...buttonComponent.value.bind }, {
    style: { color: _ctx.color },
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (setIsHover(true))),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (setIsHover(false)))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "left-icon"),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (isIconImported(_ctx.icon))
                ? (_openBlock(), _createBlock(AppIcon, {
                    key: 0,
                    src: iconSrc.value,
                    size: _ctx.iconSize,
                    class: _normalizeClass(_ctx.iconClass)
                  }, null, 8, ["src", "size", "class"]))
                : (_openBlock(), _createBlock(BaseIcon, {
                    key: 1,
                    icon: _ctx.icon,
                    class: _normalizeClass(_ctx.iconClass),
                    size: _ctx.iconSize
                  }, null, 8, ["icon", "class", "size"]))
            ], 64))
          : _createCommentVNode("", true),
        (buttonLabel.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["app-button__label", _ctx.labelClass])
            }, _toDisplayString(buttonLabel.value), 3))
          : _createCommentVNode("", true),
        (_ctx.trailingIcon)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (isIconImported(_ctx.trailingIcon))
                ? (_openBlock(), _createBlock(AppIcon, {
                    key: 0,
                    src: trailingIconSrc.value,
                    size: _ctx.trailingIconSize,
                    class: _normalizeClass([
            _ctx.iconClass,
            {
              'app-button__trailing-icon':
                _ctx.shiftTrailingIconOnHover && isHovered.value,
            },
          ])
                  }, null, 8, ["src", "size", "class"]))
                : (_openBlock(), _createBlock(BaseIcon, {
                    key: 1,
                    icon: _ctx.trailingIcon,
                    class: _normalizeClass(_ctx.iconClass),
                    size: _ctx.trailingIconSize
                  }, null, 8, ["icon", "class", "size"]))
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["disabled", "type", "class", "style"]))
}
}

})