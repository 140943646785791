import { useModel as _useModel, useCssVars as _useCssVars, unref as _unref, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-modal__content__sidebar"
}
const _hoisted_2 = { class: "app-modal__content__container" }
const _hoisted_3 = {
  key: 1,
  class: "app-modal__content__body"
}
const _hoisted_4 = {
  key: 2,
  class: "app-modal__content__footer"
}

import { computed, useTemplateRef } from "vue";
import { AppModalSize } from "@/shared/types/components";
import { AppModalSizeEnum } from "@/shared/enums/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppModal',
  props: /*@__PURE__*/_mergeModels({
    size: { default: "medium" },
    showHeaderBorder: { type: Boolean }
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

_useCssVars(_ctx => ({
  "3490b628": (getSize.value)
}))

const model = _useModel<boolean>(__props, "modelValue");



const appModal = useTemplateRef<HTMLDivElement>("appModal");

const getSize = computed(() => AppModalSizeEnum[__props.size]);

const hideModal = () => {
  model.value = false;
};

const handleAppModalClick = (event: MouseEvent) => {
  if (event.target === appModal.value) {
    hideModal();
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_esc_keydown = _resolveDirective("esc-keydown")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-modal', { 'app-modal--hidden': !model.value }]),
    ref_key: "appModal",
    ref: appModal,
    onMousedown: handleAppModalClick
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (model.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
          'app-modal__content',
          {
            'app-modal__content--full': _ctx.size === 'fullscreen',
          },
        ])
            }, [
              (_ctx.$slots.sidebar)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "sidebar", { hideModal: hideModal })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                (_ctx.$slots.header)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([
              'app-modal__content__header',
              {
                'app-modal__content__header--bordered': _ctx.showHeaderBorder,
              },
            ])
                    }, [
                      _renderSlot(_ctx.$slots, "header", { hideModal: hideModal })
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.$slots.body)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "body", { hideModal: hideModal })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.footer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "footer", { hideModal: hideModal })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 34)), [
    [_directive_esc_keydown, hideModal]
  ])
}
}

})