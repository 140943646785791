<template>
  <div class="app-tooltip" ref="appTooltip">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef, watch, onMounted } from "vue";
import tippy, { Instance, hideAll, Props } from "tippy.js";

const {
  content,
  hideOnClick = true,
  showIf = true,
  arrow = true,
  allowHtml = false,
  appendTo = document.body,
  maxWidth = 350,
  offsetX = 0,
  offsetY = 0,
  delay = 0,
} = defineProps<{
  content: string;
  allowHtml?: boolean;
  hideOnClick?: boolean;
  showIf?: boolean;
  arrow?: boolean;
  appendTo?: "parent" | Element;
  maxWidth?: string | number;
  offsetX?: number;
  offsetY?: number;
  delay?: number | Array<null | number>;
}>();

const appTooltip = useTemplateRef<HTMLDivElement>("appTooltip");

const instance = ref<Instance<Props>>();

const createTippyInstance = (content: string) => {
  return tippy(appTooltip.value!, {
    animation: "shift-away-subtle",
    hideOnClick: hideOnClick,
    theme: "app-tooltip-light",
    allowHTML: allowHtml,
    appendTo: appendTo,
    interactiveBorder: 4,
    interactive: true,
    arrow: arrow,
    content,
    offset: [offsetX, offsetY],
    maxWidth: maxWidth,
    delay: delay,
    onShow(instance) {
      hideAll({ exclude: instance });
    },
  });
};

watch(
  () => content,
  (newContent: string) => {
    if (instance.value) {
      instance.value.destroy();

      instance.value = createTippyInstance(newContent);
    }
  },
);

onMounted(() => {
  if (showIf && content.length) {
    instance.value = createTippyInstance(content);
  }
});
</script>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/dist/border.css";
@import "tippy.js/animations/shift-away-subtle.css";

.app-tooltip {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.tippy-box[data-theme~="app-tooltip-light"] {
  background-color: $white;
  color: $gray;
  border: 1px solid $border;
  overflow-wrap: break-word;
  z-index: 12;
  text-align: left;
  font-size: rem(12px);
  line-height: rem(24px);
}

.tippy-content {
  overflow: hidden;
}

.tippy-arrow {
  color: $white;
  border-color: $border;
}
</style>
