import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import axios from "axios";
import store from "@/stores";

const { notify, isNotificationActive } = useNotificationsStore(store);

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

let currentNotificationId: string;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.message === "Network Error" &&
      !isNotificationActive(currentNotificationId)
    ) {
      currentNotificationId = notify(
        "Connection issue. Please check your internet connection.",
        "danger",
      );
    }

    return Promise.reject(error);
  },
);

export default instance;
