<template>
  <div class="app-empty-layout">
    <AppExpiryBanner
      v-if="webCustomerStore.showSubscriptionBanner"
      @open-book-call-modal="handleOpenBookCallModal"
    />
    <header class="app-empty-layout__navigation-bar">
      <AppLink
        to="/dashboard"
        link-component="router-link"
        class="app-empty-layout__navigation-bar__brand"
      >
        <img
          :src="netZeroInsightsLogoHighRes"
          alt="Net Zero Insights"
          class="app-empty-layout__navigation-bar__brand__logo"
        />
      </AppLink>

      <AppButton
        v-if="webCustomerStore.webCustomer.preview"
        href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
        label="Upgrade"
        size="small"
        font-size="12px"
        height="30px"
      />
    </header>

    <BookCallModal v-model="bookCallModal" />

    <main class="app-empty-layout__body">
      <div class="app-empty-layout__body__content">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import { netZeroInsightsLogoHighRes } from "@/shared/constants/icons";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { ref } from "vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";

const webCustomerStore = useWebCustomerStore();

const bookCallModal = ref(false);

const handleOpenBookCallModal = () => {
  bookCallModal.value = !bookCallModal.value;
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-empty-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16px) rem(48px);
    height: rem(64px);
    width: 100%;
    position: fixed;
    z-index: 11;
    background-color: $white;
    border-bottom: 1px solid $border;
    box-sizing: border-box;

    &__brand {
      display: flex;
      align-items: center;

      &__logo {
        width: rem(100px);
        height: auto;
      }
    }
  }

  &__body {
    margin: rem(48px) 0;
    height: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      background-color: $background;
      flex-grow: 1;
      border-top: 1px solid $border;
      border-left: 1px solid $border;
    }
  }
}
</style>
