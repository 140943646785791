
import { AppSidebarLink } from "@/shared/types/components";
import { defineComponent, PropType } from "vue";
import { mapStores } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppSidebarItem from "@/components/app/AppSidebarItem/AppSidebarItem.vue";

const __default__ = defineComponent({
  name: "AppSidebar",
  components: { AppSidebarItem },
  props: {
    links: {
      type: Array as PropType<AppSidebarLink[]>,
      required: true,
    },
  },

  data: () => ({
    isSidebarHovered: false,
    navbarHeight: 64,
    expiryBannerHeight: 32,
    sidebarHeightDifference: "64px",
  }),

  computed: {
    ...mapStores(useWebCustomerStore),
  },

  methods: {
    handleSidebarMouseOver() {
      if (!this.isSidebarHovered) {
        this.isSidebarHovered = true;
      }
    },
    handleSidebarMouseLeave() {
      if (this.isSidebarHovered) {
        this.isSidebarHovered = false;
      }
    },
  },
  watch: {
    "webCustomerStore.showSubscriptionBanner": {
      immediate: true,
      handler(showSubscriptionBanner) {
        if (showSubscriptionBanner) {
          this.sidebarHeightDifference = `${this.navbarHeight + this.expiryBannerHeight}px`;
        } else {
          this.sidebarHeightDifference = `${this.navbarHeight}px`;
        }
      },
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7a344776": (_ctx.sidebarHeightDifference)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__