import type { DirectiveBinding } from "vue";

interface EscKeyElement extends HTMLElement {
  _escKeydownHandler?: (event: KeyboardEvent) => void;
}

export const escKeydown = {
  mounted(el: EscKeyElement, binding: DirectiveBinding) {
    el._escKeydownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        binding.value(event); // Execute the provided handler
      }
    };

    window.addEventListener("keydown", el._escKeydownHandler);
  },

  unmounted(el: EscKeyElement) {
    if (el._escKeydownHandler) {
      window.removeEventListener("keydown", el._escKeydownHandler);
      delete el._escKeydownHandler;
    }
  },
};
