<template>
  <li
    :class="[
      'app-sidebar-item',
      {
        'app-sidebar-item--has-link': item.path,
        'app-sidebar-item--child': isChild,
        'app-sidebar-item--active': isActiveLink,
      },
    ]"
  >
    <component
      :is="linkElementBinding.component"
      :link-component="linkElementBinding.component"
      v-bind="linkElementBinding.bind"
      :class="[
        'app-sidebar-item__link',
        { 'app-sidebar-item__link--child': isChild },
      ]"
    >
      <AppIcon
        v-if="item.icon"
        :src="isActiveLink ? item.activeIcon : item.icon"
        size="24px"
      />
      <span
        v-if="isSidebarCollapsed"
        :class="[
          'app-sidebar-item__link__name',
          { 'app-sidebar-item__link__name--child': isChild },
        ]"
      >
        {{ item.name }}
      </span>
    </component>

    <ul
      class="app-sidebar-item__children"
      v-if="isActiveLink && item.children?.length && isSidebarCollapsed"
    >
      <AppSidebarItem
        v-for="child in item.children"
        :key="child.name"
        :item="child"
        :is-sidebar-collapsed="isSidebarCollapsed"
        :is-child="true"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { AppSidebarLink } from "@/shared/types/components";
import { defineComponent, PropType } from "vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

export default defineComponent({
  name: "AppSidebarItem",
  components: { AppIcon, AppLink },

  props: {
    isChild: Boolean,
    isSidebarCollapsed: Boolean,
    item: {
      type: Object as PropType<AppSidebarLink>,
      required: true,
    },
  },

  computed: {
    isActiveLink() {
      const routes = this.$router.getRoutes();

      const searchPageLinks = [
        "/organizations/saved-search/",
        "/funding-rounds/saved-search/",
        "/user/savedSearch/organizations/",
        "/user/savedSearch/funding-rounds/",
        "/lists/",
        "/investors/",
        "/similar-companies",
      ];

      const portfolioPageLinks: string[] = [];

      routes.forEach((route) => {
        if (["Portfolios", "Portfolio"].includes(route.name as string)) {
          portfolioPageLinks.push(route.path);
        }
      });

      if (portfolioPageLinks.includes(this.$route.path)) {
        return this.item.path === "/lists";
      }

      if (this.$route.name === "AdminPanel") {
        return this.item.path === "/admin-panel";
      }

      if (searchPageLinks.includes(this.$route.path)) {
        return this.item.path === "/search";
      }

      return this.$route.path.includes(this.item.path as string);
    },

    linkElementBinding() {
      const { path, isExternal } = this.item;
      if (path) {
        if (isExternal) {
          return {
            component: "AppLink",
            bind: {
              linkComponent: "a",
              href: path,
            },
          };
        } else {
          return {
            component: "AppLink",
            bind: {
              linkComponent: "router-link",
              to: path,
            },
          };
        }
      } else {
        return {
          component: "div",
          bind: {},
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/styles/functions";
@import "src/styles/colors";

.app-sidebar-item {
  padding: rem(12px);
  border-radius: rem(8px);
}

.app-sidebar-item--has-link:hover,
.app-sidebar-item--active {
  background-color: $backgroundAlternative;
}

.app-sidebar-item--child {
  padding: rem(4px) rem(4px) rem(4px) rem(32px);
}

.app-sidebar-item--child:hover,
.app-sidebar-item--child.app-sidebar-item--active {
  background-color: $background;
}

.app-sidebar-item__link {
  display: flex;
  align-items: center;
}

.app-sidebar-item__link--child {
  padding-left: rem(8px);
}

.app-sidebar-item__link__name {
  margin-left: rem(16px);
}

.app-sidebar-item--active
  .app-sidebar-item__link__name:not(.app-sidebar-item__link__name--child) {
  color: $primary;
  font-weight: 600;
}

.app-sidebar-item__link__name--child {
  margin-left: 0;
}

.app-sidebar-item__link.router-link-exact-active
  .app-sidebar-item__link__name--child {
  color: $primary;
}

.app-sidebar-item__children {
  margin-top: rem(10px);
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: rem(4px);
}
</style>
